import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { CardBody, Row, Spinner } from 'reactstrap';
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';
import { loginAluno } from '../../redux/actions/alunosActions';

const LogInAluno = (props) => {
  const dispatch = useDispatch();
  const { aluno } = useSelector((state) => state.alunos.auth);
  const error = useSelector((state) => state.alunos.error);
  const isFetching = useSelector((state) => state.alunos.isFetching);
  const { emailAluno, idUscreen } = props.match.params;

  useEffect(() => {
    dispatch(loginAluno(emailAluno, idUscreen));
  }, [dispatch, emailAluno, idUscreen]);

  return (
    <div>
      {isFetching && <Spinner className="loading-spinner" />}
      {!isFetching && !error && aluno?._id && <Redirect to="/home" />}
      {!isFetching && error && (
        <CardBody className="cardbody-confirmacao">
          <CloseCircleOutlineIcon className="icon-confirmacao--fail" />
          <h4>{`Acesso não permitido. ${error.error}`}</h4>
          <p>Este acesso é exclusivo para usuários do Nerdflix.</p>
          <p>Verifique seu email de acesso e se a sua assinatura está ativa.</p>
          <p>
            Caso precise de ajuda, entre em contato:{' '}
            <a href="mailto:atendimento@paulogentil.com">
              atendimento@paulogentil.com
            </a>
          </p>
          <Row className="buttontoolbar-confirmacao">
            <a
              className="btn btn-primary"
              href="https://nerdflix.paulogentil.com/pages/certificados"
            >
              Voltar
            </a>
          </Row>
        </CardBody>
      )}
    </div>
  );
};

export default LogInAluno;
