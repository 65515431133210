import React from 'react';
import { Col, Row } from 'reactstrap';

const Footer = () => (
  <footer className="footer">
    <Row className="mx-1 pt-2">
      <Col md={3} lg={3} xl={3}>
        <p className="footer-text-left">© Nerdflix</p>
        <p className="footer-text-left">
          Designed by{' '}
          <a
            href="https://www.advanced-digital.com.br/"
            target="_blank"
            rel="noreferrer"
          >
            Advanced Digital
          </a>
        </p>
      </Col>

      <Col md={6} lg={6} xl={6} className="mx-auto mb-4">
        <a
          className="footer__nav-link py-2"
          href="https://nerdflix.paulogentil.com/pages/termos-de-uso"
        >
          Termos de Uso
        </a>
        <a
          className="footer__nav-link"
          href="https://nerdflix.paulogentil.com/pages/politica-de-privacidade"
        >
          Política de privacidade
        </a>
        <a
          className="footer__nav-link"
          href="https://nerdflix.paulogentil.com/pages/politica-de-cancelamento"
        >
          Política de Cancelamento
        </a>
        <a
          className="footer__nav-link"
          href="https://nerdflix.paulogentil.com/pages/central-de-ajuda"
        >
          Central de Ajuda
        </a>
      </Col>

      <Col md={3} lg={3} xl={3}></Col>
    </Row>
  </footer>
);

export default Footer;
